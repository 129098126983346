import {
    LOGIN,
    SET_LOGIN_STATUS,

    FETCH_TASKS,
    FETCH_CUSTOM_TASKS,
    FETCH_FILTERED_TASKS,
    FETCH_CHECKLIST,

    OPEN_CUSTOM_TASK_DIALOG,
    OPEN_EDIT_CUSTOM_TASK_DIALOG,
    SAVE_CUSTOM_TASK,

    SHOW_PRELOADER,
    HIDE_PRELOADER,
    SET_TOOLBAR_TITLE,
    DISPLAY_MESSAGE_BAR,
    SET_PHOTOS,
    SHOW_CONFIRMATION,
    ADD_PHOTO,

    GET_VEHICLE_TYPE,
    SELECT_VEHICLE_TYPE,
    GET_VEHICLE_GROUP,
    SELECT_VEHICLE_GROUP,
    SET_REGISTRATION_NUMBER,
    SELECT_REGISTRATION_NUMBER
} from './actions';
import russian from '../../languages/russian';

const initialState = {
    user: {},
    login_status: null,

    tasksList: [],
    customTasksList: [],
    filteredTasksList: [],
    checklist: [],

    open_custom_task_dialog: false,
    open_edit_custom_task_dialog: null, // null or an id
    detailedTask: {
        vehicle_id: '',
        due_date: '',
        text: '',
        priority: '',
        images: []
    },

    showPreloader: false,
    toolBarTitle: '',
    lng: russian(),
    messageBar: '',
    messageBarType: '',
    photos: [],
    confirmation: {
        open: false,
        title: '',
        message: '',
        actions: {
            accept: () => {},
            cancel: () => {},
        }
    },

    vehicleTypes: [],
    vehicleGroups: [],
    selectedVehicleTypes: [],
    selectedVehicleGroups: [],
    reg_numbers: [],
    selected_reg_number: []
};

const commonReducers = (state, action) => {
    switch (action.type) {
        case FETCH_TASKS:
            return {...state, tasksList: action.payload};

        case FETCH_CUSTOM_TASKS: {
            return {...state, customTasksList: action.payload};
        }

        case FETCH_FILTERED_TASKS : {
            return {...state, filteredTasksList: action.payload};
        }

        case SHOW_PRELOADER:
            return {...state, showPreloader: action.payload};

        case HIDE_PRELOADER:
            return {...state, showPreloader: action.payload};

        case SET_TOOLBAR_TITLE:
            return {...state, toolBarTitle: action.payload};

        case GET_VEHICLE_TYPE:
            return {...state, vehicleTypes: action.payload};

        case GET_VEHICLE_GROUP:
            return {...state, vehicleGroups: action.payload};

        case SET_REGISTRATION_NUMBER:
            return {...state, reg_numbers: action.payload};

        case SELECT_VEHICLE_TYPE:
            return {...state, selectedVehicleTypes: action.payload};

        case SELECT_VEHICLE_GROUP:
            return {...state, selectedVehicleGroups: action.payload};

        case FETCH_CHECKLIST:
            return {...state, checklist: action.payload};

        case DISPLAY_MESSAGE_BAR:
            return {...state, messageBar: action.message, messageBarType: action.barType};

        case SET_PHOTOS:
            return {...state, photos: action.payload};

        case SELECT_REGISTRATION_NUMBER:
            return {...state, selected_reg_number: action.payload};

        case OPEN_CUSTOM_TASK_DIALOG:
            return {...state, open_custom_task_dialog: action.payload};

        case OPEN_EDIT_CUSTOM_TASK_DIALOG:
            return {...state, open_edit_custom_task_dialog: action.payload};

        case SAVE_CUSTOM_TASK:
            return {...state, detailedTask: action.payload};

        case SHOW_CONFIRMATION:
            return {
                ...state,
                confirmation: {
                    open: action.open,
                    title: action.title,
                    message: action.message,
                    actions: {
                        accept: action.accept,
                        cancel: action.cancel,
                    }
                }
            };

        case LOGIN:
            return {...state, user: action.payload};

        case SET_LOGIN_STATUS:
            return {...state, login_status: action.payload};

        default:
            return initialState;
    }
};

export default commonReducers;