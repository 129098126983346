import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';
import MessageBar from './layouts/MessageBar';
import Confirmation from './layouts/Confirmation';
import {connect} from 'react-redux';
import {Redirect} from 'react-router-dom';


import Login from './containers/Login';
import Dashboard from './pages/Dashboard';

const RouteList = (props) => {
    if (props.user.token !== undefined) {
        return (
            <React.Fragment>
                <Route path='/' component={Dashboard}/>
                {/*<Route render={() => {return <Redirect to="/" />}}/>*/}
            </React.Fragment>
        )
    } else {
        return (
            <React.Fragment>
                <Route path='/' exact component={Login}/>
                <Route render={() => {return <Redirect to="/" />}}/>
            </React.Fragment>
        )
    }
};

class App extends Component {
    render() {
        const {user} = this.props;

        return (
            <div className="App">
                <Switch>
                    {/*<Route path='/dashboard' component={Dashboard}/>*/}
                    {/*<Route path='/' exact component={Login}/>*/}
                    <RouteList user={user}/>
                </Switch>
                <MessageBar/>
                <Confirmation/>
            </div>
        )
    }

}

const mapStateToProps = state => {
    return {
        user: state.common.user
    }
};

export default connect(mapStateToProps)(App);
