import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    setToolbarTitle,
    axiosFetchTasks,
    axiosFetchVehicles,
    showPreloader,
    hidePreloader,
    fetchTasks,
    fetchFilteredTasks,
    selectRegistrationNumber
} from "../../store/common/actions";
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import { createStyles, withStyles } from '@material-ui/core/styles';
import ChooseVehicle from '../../layouts/filter/ChooseVehicle';
import ChooseGroup from '../../layouts/filter/ChooseGroup';
import RegistrationNumber from '../../layouts/filter/RegistrationNumber';
import VehicleList from "../../layouts/VehicleList";
import Preloader from "../../layouts/Preloader";
import {COLORS} from "../../constants";

const styles = theme => createStyles({
    btn: {
        marginTop: 20,
        minWidth: 180,
        height: 40,
        color: COLORS.black,
        backgroundColor: COLORS.gray,

        ['&:hover, &:focus']: {
            color: COLORS.black,
            backgroundColor: COLORS.gray,
        }
    },

    input: {
        width: '100%'
    },

    label: {
        textAlign: 'left',
        fontSize: 14
    },

    divider: {
        marginTop: 30,
        marginBottom: 30
    }
});

class Filter extends Component {
    loadTasks = async (args) => {
        this.props.fetchFilteredTasks([]);
        this.props.showPreloader();

        await this.props.axiosFetchVehicles(args);

        this.props.hidePreloader();
    };

    filter = () => {
        let filter = {};

        let vehicle_type_id = [];
        let vehicle_group_id = [];
        let vehicle_id = [];

        if (this.props.vehicles_types.length > 0) {
            vehicle_type_id = this.props.vehicles_types.map(v => {
                return v.id
            });

            vehicle_type_id = vehicle_type_id.join(',');

            filter.type_id = vehicle_type_id;
        }

        if (this.props.vehicles_groups.length > 0) {
            vehicle_group_id = this.props.vehicles_groups.map(g => {
                return g.id
            });

            vehicle_group_id = vehicle_group_id.join(',');

            filter.group_id = vehicle_group_id;
        }

        if (this.props.reg_number.length > 0) {
            vehicle_id = this.props.reg_number.map(g => {
                return g.label
            });

            vehicle_id = vehicle_id.join(',');

            filter.registration_number = vehicle_id;
        }

        this.loadTasks(filter);
    };

    componentDidMount() {
        this.props.setToolbarTitle( this.props.lng.filter );
        this.loadTasks({});
    }

    render() {
        const {
            lng,
            classes,
            tasks,
        } = this.props;

        return (
            <div>
                <ChooseVehicle/>
                <ChooseGroup />
                <RegistrationNumber />

                <div style={{textAlign: 'center'}}>
                    <Button variant="contained" color="primary" onClick={this.filter} className={classes.btn}>
                        {lng.filter}
                    </Button>
                </div>

                <Divider className={classes.divider} variant="middle" />

                <VehicleList />
                <Preloader />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        lng: state.common.lng,
        reg_numbers: state.common.reg_numbers,
        tasks: state.common.filteredTasksList,
        vehicles_types: state.common.selectedVehicleTypes,
        vehicles_groups: state.common.selectedVehicleGroups,
        reg_number: state.common.selected_reg_number
    }
};

const mapDispatchToProps = {
    setToolbarTitle,
    axiosFetchTasks,
    axiosFetchVehicles,
    showPreloader,
    hidePreloader,
    fetchTasks,
    fetchFilteredTasks
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Filter))