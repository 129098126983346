import React, {Component} from 'react';
import {connect} from 'react-redux';
import CircularProgress from '@material-ui/core/CircularProgress';

class Preloader extends Component{
    render() {
        return (
            <div style={{textAlign: 'center'}}>
                {this.props.show &&
                    <CircularProgress disableShrink />
                }
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        show: state.common.showPreloader
    }
};

export default connect(mapStateToProps)(Preloader)