import React, {Component} from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import {connect} from 'react-redux';
import {displayMessageBar} from '../store/common/actions';
import PropTypes from 'prop-types';

import { createStyles, withStyles } from '@material-ui/core/styles';

const styles = theme => createStyles({
    snackbar: {
        flexWrap: 'nowrap',
        alignItems: 'center',

        ['&.error']: {
            backgroundColor: 'red'
        },

        ['&.success']: {
            backgroundColor: 'green'
        }
    }
});

class MessageBar extends Component {
    render() {
        const {message, type, displayMessageBar, classes} = this.props;

        return (
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={(message.length > 0)}
                // autoHideDuration={10000}
                onClose={() => displayMessageBar('', '')}
            >
                <SnackbarContent
                    aria-describedby="client-snackbar"
                    message={message}
                    className={classes.snackbar+' '+type}
                    action={[
                        <IconButton key="close" aria-label="close" color="inherit" onClick={() => displayMessageBar('', '')}>
                            <CloseIcon />
                        </IconButton>,
                    ]}
                />
            </Snackbar>
        )
    }
}

MessageBar.propTypes = {
    message: PropTypes.string,
    type: PropTypes.string,
};

const mapStateToProps = state => {
    return {
        message: state.common.messageBar,
        type: state.common.messageBarType,
    }
};

const mapDispatchToProps = {
    displayMessageBar
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MessageBar))