import React, {Component} from 'react';
import {connect} from 'react-redux';
import { createStyles, withStyles } from '@material-ui/core/styles';
import CameraIcon from '@material-ui/icons/Camera';
import ClearIcon from '@material-ui/icons/Clear';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import {setPhotos, displayMessageBar} from '../store/common/actions';
import ExifOrientationImg from 'react-exif-orientation-img'
import {COLORS} from "../constants";
import {isMobile, isBrowser} from 'react-device-detect';

const styles = theme => createStyles({
    btn: {
        color: COLORS.black,
        backgroundColor: COLORS.gray,
        borderRadius: 4,
        display: 'inline-flex',
        padding: '5px 15px',
        alignItems: 'center',
        lineHeight: 1,
        minWidth: 150,
        height: 30,
        justifyContent: 'center',

        ['&:hover, &:focus']: {
            color: COLORS.black,
            backgroundColor: COLORS.gray,
        }
    },

    icon: {
        marginRight: 10
    },

    wrap: {
        textAlign: 'center'
    },

    list: {
        listStyle: 'none',
        padding: 0,
        margin: '0 -3px',
        textAlign: 'center'
    },

    list_item: {
        display: 'inline-block',
        padding: 6,
        position: 'relative'
    },

    list_img: {
        width: '100px',
        height: '100px',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    },

    list_remove: {
        color: '#fff',
        backgroundColor: '#f00',
        outline: 'none !important',
        width: 30,
        height: 30,
        padding: 0,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: 'auto',

        ['&:hover, &:focus']: {
            color: '#fff',
            backgroundColor: '#f00',
        }
    }
});

class MakePhoto extends Component {
    upload = async (e) => {
        if (this.props.photos.length === 5) {
            this.props.displayMessageBar('Вы можете загрузить только 5 изображений', 'error');
            return false;
        }

        if (e.currentTarget.files.length > 0) {
            const file = e.currentTarget.files[0];
            const preview = await this.getUrl(file);

            let photos = this.props.photos;

            photos.push({
                file,
                preview
            });

            this.props.setPhotos([]);
            this.props.setPhotos(photos);
        }
    };

    getUrl = (file) => new Promise(resolve => {
        const reader = new FileReader();

        reader.onload = function (event) {
            resolve(reader.result)
        };

        reader.readAsDataURL(file);
    });

    remove = (index) => {
        let photos = this.props.photos.filter((img,i) => i !== index);
        this.props.setPhotos(photos);
    };

    componentDidMount() {
        this.props.setPhotos([]);
    }

    // orientation(img) {
    //
    //     // Set variables
    //     let canvas = document.createElement('canvas');
    //     let ctx = canvas.getContext("2d");
    //     let exifOrientation = '';
    //     let width = img.width,
    //         height = img.height;
    //
    //     // Check orientation in EXIF metadatas
    //     window.EXIF.getData(img, function() {
    //         let allMetaData = EXIF.getAllTags(this);
    //         exifOrientation = allMetaData.Orientation;
    //         console.log('Exif orientation: ' + exifOrientation);
    //     });
    //
    //     // set proper canvas dimensions before transform & export
    //     if (exifOrientation.includes([5, 6, 7, 8]))  {
    //         canvas.width = height;
    //         canvas.height = width;
    //     } else {
    //         canvas.width = width;
    //         canvas.height = height;
    //     }
    //
    //     // transform context before drawing image
    //     switch (exifOrientation) {
    //         case 2:
    //             ctx.transform(-1, 0, 0, 1, width, 0);
    //             break;
    //         case 3:
    //             ctx.transform(-1, 0, 0, -1, width, height);
    //             break;
    //         case 4:
    //             ctx.transform(1, 0, 0, -1, 0, height);
    //             break;
    //         case 5:
    //             ctx.transform(0, 1, 1, 0, 0, 0);
    //             break;
    //         case 6:
    //             ctx.transform(0, 1, -1, 0, height, 0);
    //             break;
    //         case 7:
    //             ctx.transform(0, -1, -1, 0, height, width);
    //             break;
    //         case 8:
    //             ctx.transform(0, -1, 1, 0, 0, width);
    //             break;
    //         default:
    //             ctx.transform(1, 0, 0, 1, 0, 0);
    //     }
    //
    //     // Draw img into canvas
    //     ctx.drawImage(img, 0, 0, width, height);
    //
    //     return ctx.toDataURL();
    // }


    // orientation(img) {
    //
    //     EXIF.getData(img, function () {
    //         var orientation = img.exifdata.Orientation
    //
    //         // 2. Invoke `exifOrient` to orient the image and get back a canvas
    //         global.EXIF.exifOrient(img, orientation, function (err, canvas) {
    //
    //             // 3. Do whatever you want with the canvas, e.g. render it into an image
    //         })
    //     })
    // }


    render() {
        const {lng, classes, photos} = this.props;

        return (
            <div className={classes.wrap}>
                <ul className={classes.list}>
                    {photos.map( (img, i) => {
                        return (
                            <li key={i} className={classes.list_item}>
                                <CardMedia
                                    image={img.preview}
                                    title="Image"
                                    className={classes.list_img}
                                >
                                    <ExifOrientationImg width="100" height={100} styles={{objectFit:'cover'}} src={img.preview}/>
                                </CardMedia>
                                <IconButton className={classes.list_remove} onClick={() => this.remove(i)}>
                                    <ClearIcon/>
                                </IconButton>
                            </li>
                        )
                    } )}
                </ul>
                <input type="file" id="make_photo_input" disabled={!isMobile} accept="image/*" capture="camera" style={{display: 'none'}} onChange={this.upload}/>
                <label htmlFor="make_photo_input" className={classes.btn}>
                    <CameraIcon className={classes.icon} /> {lng.make_photos}
                </label>
            </div>
        )
    }
}

const mapStateToProps = state => {
  return {
      lng: state.common.lng,
      photos: state.common.photos
  }
};

const mapDispacthToProps = {
    setPhotos,
    displayMessageBar
};

export default connect(mapStateToProps, mapDispacthToProps)(withStyles(styles)(MakePhoto));