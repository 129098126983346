import React, {Component} from 'react';
import {connect} from 'react-redux';
import { createStyles, withStyles } from '@material-ui/core/styles';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import {
    axiosGetRegistrationNumbers,
    openCustomTaskDialog,
    selectRegistrationNumber,
    axiosCreateCustomTask,
    displayMessageBar,
    axiosFetchAllTasks
} from '../../store/common/actions';
import MakePhoto from "../MakePhoto";
import Button from "@material-ui/core/Button";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import TextField from "@material-ui/core/TextField";
import {COLORS, PRIORITY_COLORS} from "../../constants";
import RegistrationNumber from "../filter/RegistrationNumber";
import FormHelperText from "@material-ui/core/FormHelperText";
import {withRouter} from 'react-router-dom';
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = theme => createStyles({
    dialog: {
        width: '100%',
        margin: 30,
        paddingTop: 15,
        paddingBottom: 15,
        textAlign: 'center'
    },

    formGroup: {
        width: '100%',
        marginBottom: 15
    },

    priorityGroup: {
        flexWrap: 'nowrap',
        flexDirection: 'initial',
        alignItems: 'center',
        margin: '0 0 15px'
    },

    low: {
        color: PRIORITY_COLORS.low,

        '&:checked': {
            color: PRIORITY_COLORS.low,
        },
    },

    medium: {
        color: PRIORITY_COLORS.medium,

        '&:checked': {
            color: PRIORITY_COLORS.medium,
        },
    },

    high: {
        color: PRIORITY_COLORS.high,

        '&:checked': {
            color: PRIORITY_COLORS.high,
        },
    },

    save: {
        margin: '30px 10px 0',
        minWidth: 180,
        height: 40,
        color: COLORS.black,
        backgroundColor: COLORS.gray,

        ['&:hover, &:focus']: {
            color: COLORS.black,
            backgroundColor: COLORS.gray,
        }
    },

    priority_label: {
        fontSize: 12
    },
});

class Add extends Component {
    state = {
        error: {
            title: false,
            reg_numbers: false,
            due_date: false,
            comment: false,
            priority: false
        },

        title_value: '',

        sendingPreloader: false
    };

    writeTitle = (e) => {
        const title_value = e.currentTarget.value;

        if (title_value.length < 15) {
            this.setState({title_value});
        }
    };

    validation = () => {
        let error = this.state.error;
        let new_title = this.state.title_value;

        const reg_numbers = this.props.selected_reg_numbers.map(n => {
            return n.value
        });

        const title = document.getElementById('title').value;
        // const due_date = document.getElementById('due_date').value;
        const comment = document.getElementById('comment').value;
        const priority = document.querySelector('input[name="priority"]:checked') ? document.querySelector('input[name="priority"]:checked').value : '';

        if (title.length === 0) {
            error.title = true;
            new_title = title;
        } else {
            if (title.length < 35) {
                new_title = title;
                error.title = false;
            }
        }

        if (reg_numbers.length === 0) {
            error.reg_numbers = true;
        } else {
            error.reg_numbers = false;
        }

        // if (due_date.length === 0) {
        //     error.due_date = true;
        // } else {
        //     error.due_date = false;
        // }

        if (comment.length === 0) {
            error.comment = true;
        } else {
            error.comment = false;
        }

        if (priority.length === 0) {
            error.priority = true;
        } else {
            error.priority = false;
        }

        this.setState({error, title_value: new_title})
    };

    submit = async (e) => {
        e.preventDefault();

        this.validation();

        const reg_numbers = this.props.selected_reg_numbers.map(n => {
            return n.value
        });
        const vehicle_id = reg_numbers.join(',');
        const text = document.getElementById('title').value;
        // const due_date = document.getElementById('due_date').value;
        const comment = document.getElementById('comment').value;
        const priority = document.querySelector('input[name="priority"]:checked') ? document.querySelector('input[name="priority"]:checked').value : '';

        let formData = new FormData();

        formData.append('vehicle_id', vehicle_id);
        formData.append('text', text);
        // formData.append('due_date', due_date);
        formData.append('comment', comment);
        formData.append('priority', priority);

        this.props.photos.map(p => {
            formData.append('images[]', p.file)
        });

        if (comment.length > 0 && priority.length > 0 && vehicle_id.length > 0) {
            this.setState({sendingPreloader: true});
            const sent = await axiosCreateCustomTask(formData);

            if (sent) {
                this.props.axiosFetchAllTasks({record_type: 'custom'}, true);
                this.props.openCustomTaskDialog(false);
                this.props.displayMessageBar('Задача была создана', 'success');
            } else {
                this.props.displayMessageBar('Произошла ошибка', 'error');
            }

            this.setState({sendingPreloader: false});
        }
    };

    render() {
        const {open, lng, openCustomTaskDialog, classes,} = this.props;
        const {error, sendingPreloader, title_value} = this.state;

        return (
            <Dialog
                open={open}
                onExited={() => this.setState({title_value: ''})}
                onClose={() => openCustomTaskDialog(false, {})}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{classes: {root: classes.dialog}}}
            >
                <DialogTitle>{lng.add_new_task}</DialogTitle>
                <DialogContent>
                            <form onSubmit={this.submit}>
                                <TextField
                                    name="title"
                                    label={lng.name}
                                    type="text"
                                    id="title"
                                    value={title_value}
                                    onChange={this.validation}
                                    className={classes.formGroup}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />

                                {error.title &&
                                <FormHelperText style={{color: 'red', margin: '5px 0 15px'}}>
                                    {lng.field_required}
                                </FormHelperText>
                                }

                                <div className={classes.formGroup}>
                                    <RegistrationNumber isMulti={false} />
                                </div>

                                {error.reg_numbers &&
                                <FormHelperText style={{color: 'red', margin: '5px 0 15px'}}>
                                    {lng.field_required}
                                </FormHelperText>
                                }

                                {/*<TextField*/}
                                    {/*name="due_date"*/}
                                    {/*label={lng.date}*/}
                                    {/*type="date"*/}
                                    {/*id="due_date"*/}
                                    {/*onChange={this.validation}*/}
                                    {/*className={classes.formGroup}*/}
                                    {/*InputLabelProps={{*/}
                                        {/*shrink: true,*/}
                                    {/*}}*/}
                                {/*/>*/}

                                {/*{error.due_date &&*/}
                                {/*<FormHelperText style={{color: 'red', margin: '-10px 0 15px'}}>*/}
                                    {/*{lng.field_required}*/}
                                {/*</FormHelperText>*/}
                                {/*}*/}

                                <TextField
                                    name="comment"
                                    id="comment"
                                    label={lng.comment}
                                    onChange={this.validation}
                                    multiline
                                    className={classes.formGroup}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />

                                {error.comment &&
                                <FormHelperText style={{color: 'red', margin: '-10px 0 15px'}}>
                                    {lng.field_required}
                                </FormHelperText>
                                }

                                <RadioGroup
                                    aria-label="priority"
                                    name="priority"
                                    onChange={this.validation}
                                    className={classes.priorityGroup}
                                >
                                    <FormLabel component="legend" className={classes.priority_label}>{lng.priority}:</FormLabel>
                                    <Radio color="default" value="low" className={classes.low} />
                                    <Radio color="default" value="medium" className={classes.medium} />
                                    <Radio color="default" value="high" className={classes.high} />
                                </RadioGroup>

                                {error.priority &&
                                <FormHelperText style={{color: 'red', margin: '-10px 0 15px'}}>
                                    {lng.field_required}
                                </FormHelperText>
                                }

                                <MakePhoto/>

                                {sendingPreloader &&
                                <div>
                                    <br/>
                                    <CircularProgress disableShrink /> <br/>
                                </div>
                                }

                                <Button type="submit" variant="contained" color="primary" className={classes.save}>
                                    {lng.save}
                                </Button>
                            </form>
                </DialogContent>
            </Dialog>
        )
    }
}

const mapStateToProps = state => {
    return {
        lng: state.common.lng,
        open: state.common.open_custom_task_dialog,
        selected_reg_numbers: state.common.selected_reg_number,
        reg_numbers: state.common.reg_numbers,
        photos: state.common.photos,
        tasks: state.common.tasksList,
    }
};

const mapDispatchToProps = {
    openCustomTaskDialog,
    axiosGetRegistrationNumbers,
    selectRegistrationNumber,
    displayMessageBar,
    axiosFetchAllTasks
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Add)))