import React, {Component} from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import {
    setToolbarTitle,
    axiosFetchAllTasks,
    fetchCustomTasks,
    showPreloader,
    hidePreloader, openCustomTaskDialog
} from "../../store/common/actions";
import {connect} from 'react-redux';
import CustomTaskList from "../../layouts/CustomTaskList";
import Preloader from "../../layouts/Preloader";
import AddNewDialog from '../../layouts/custom_tasks/Add';
import EditDialog from '../../layouts/custom_tasks/Edit';
import {COLORS} from '../../constants';

const styles = theme => createStyles({
    add: {
        position: 'fixed',
        bottom: 70,
        right: 20,
        backgroundColor: COLORS.gray,
        color: COLORS.black,

        ['&:hover, &:focus']: {
            color: COLORS.black,
            backgroundColor: COLORS.gray,
        }
    },

    page: {
        paddingBottom: 80
    }
});

class AddDefect extends Component {
    loadTasks = async (args) => {
        this.props.fetchCustomTasks([]);
        this.props.showPreloader();

        await this.props.axiosFetchAllTasks(args, true);

        this.props.hidePreloader();
    };

    componentDidMount() {
        this.props.setToolbarTitle( this.props.lng.add_defect );
        this.loadTasks({record_type: 'custom'})
    };

    render() {
        const {classes, openCustomTaskDialog} = this.props;

        return (
            <div className={classes.page}>
                <CustomTaskList />
                <Preloader />

                <AddNewDialog />
                <EditDialog />

                <Fab
                    color="primary"
                    aria-label="add"
                    onClick={() => openCustomTaskDialog(true, {})}
                    className={classes.add}>
                    <AddIcon />
                </Fab>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        lng: state.common.lng
    }
};

const mapDispatchToProps = {
    setToolbarTitle,
    axiosFetchAllTasks,
    fetchCustomTasks,
    showPreloader,
    hidePreloader,
    openCustomTaskDialog
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddDefect))