import React, {Component} from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { createStyles, withStyles } from '@material-ui/core/styles';
import {COLORS, PRIORITY_COLORS} from '../constants';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {dateFormat} from '../scripts';

const styles = theme => createStyles({
    card: {
        minWidth: 275,
        marginBottom: 20,
        textAlign: 'left',
        borderRadius: 0,
        position: 'relative',

        ['&.done']: {
            borderColor: 'green'
        }
    },
    cardActions: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '5px 15px 5px 35px',
        color: theme.palette.secondary.main,
        fontSize: 16
    },
    cardUpdated: {
        color: theme.palette.secondary.main,
        display: 'block',
        fontSize: 12,
        paddingLeft: 20,
        fontWeight: 'normal'
    },
    cardContent: {
        paddingRight: 100
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 0
    },
    pos: {
        marginBottom: 0,
    },

    cardPriority: {
        display: 'inline-block',
        width: 10,
        height: 10,
        borderRadius: '50%',
        marginRight: 10,

        ['&.high']: {
            backgroundColor: PRIORITY_COLORS.high
        },

        ['&.medium']: {
            backgroundColor: PRIORITY_COLORS.medium
        },

        ['&.low']: {
            backgroundColor: PRIORITY_COLORS.low
        }
    },

    start: {
        position: 'absolute',
        top: 20,
        right: 15,
        backgroundColor: COLORS.gray+' !important',
        color: COLORS.black+' !important',
    }
});


class TaskList extends Component {
    goTo = (e,path) => {
        this.props.history.push(path)
    };

    render() {
        const {classes, lng, tasks} = this.props;

        let last_update = '';

        let checklists = '';

        return (
            <React.Fragment>
                {
                    Object.keys(tasks).map(key => {
                        last_update = (tasks[key].last_report_at && tasks[key].last_report_at.length > 0) ? lng.last_update+': '+dateFormat(tasks[key].last_report_at) : '';


                        checklists = (tasks[key].checklists !== undefined) ? tasks[key].checklists.join(',') : [];
                        if (checklists.length > 0) {
                            return (
                                <Card className={classes.card+' '+tasks[key].status} key={tasks[key].id}>
                                    <CardContent className={classes.cardContent}>
                                        <Typography variant="h5" component="h2" className={classes.title}>
                                            <span className={classes.cardPriority+' '+tasks[key].priority} />{tasks[key].vehicle_registration_number}
                                            <span className={classes.cardUpdated}>{last_update}</span>
                                        </Typography>
                                    </CardContent>
                                    <CardActions className={classes.cardActions}>
                                        <div>
                                            {tasks[key].due_date}
                                        </div>
                                        <Button
                                            className={classes.start}
                                            size="small"
                                            color="primary"
                                            variant="contained"
                                            onClick={(e) => this.goTo(e,'/task/'+tasks[key].id+'/checklist/'+tasks[key].checklists.join(',')+'/vehicle/'+tasks[key].vehicle_id+'?mileage='+tasks[key].vehicle_mileage)}
                                        >
                                            {lng.start}
                                        </Button>
                                    </CardActions>
                                </Card>
                            )
                        }
                    })
                }
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        lng: state.common.lng,
        tasks: state.common.tasksList
    }
};

const mapDispatchToProps = {

};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TaskList)))