import axios from 'axios';

var instance = axios.create({
    // baseURL: 'https://pegasfleet.dev.wippo-it.net/api',
    baseURL: 'https://fleet.pegas.md/api',
    withCredentials: false,
    // headers: {
    //     'Auth-Token': 'ddd'
    // }
});


export default instance;