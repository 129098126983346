import React, {Component} from 'react';
import {connect} from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import {showConfirmation} from '../store/common/actions';
import {createStyles, withStyles} from "@material-ui/core/styles";
import {COLORS} from "../constants";

const styles = theme => createStyles({
    dialog: {
        width: '100%'
    },

    title: {
        paddingBottom: 0
    },

    text: {
        marginBottom: 0
    },

    okBtn: {
        color: COLORS.black,
        backgroundColor: COLORS.gray,

        ['&:hover, &:focus']: {
            color: COLORS.black,
            backgroundColor: COLORS.gray,
        }
    }
});

class Confirmation extends Component {
    accept = () => {
        this.props.confirmation.actions.accept();
        this.props.showConfirmation(false)
    };

    cancel = () => {
        this.props.confirmation.actions.cancel();
        this.props.showConfirmation(false)
    };

    render() {
        const {lng, confirmation, showConfirmation, classes} = this.props;

        return (
            <Dialog
                open={confirmation.open}
                onClose={() => showConfirmation(false)}
                PaperProps={{classes: {root: classes.dialog}}}
            >
                <DialogTitle className={classes.title}>{confirmation.title}</DialogTitle>
                <DialogContent>
                    <DialogContentText className={classes.text}>
                        {confirmation.message}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" onClick={this.cancel}>
                        {lng.cancel}
                    </Button>
                    <Button color="primary" className={classes.okBtn} autoFocus onClick={this.accept}>
                        {lng.ok}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

const mapStateToProps = state => {
    return {
        lng: state.common.lng,
        confirmation: state.common.confirmation
    }
};

const mapDispatchToProps = {
    showConfirmation
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Confirmation))