// export const BASE_MEDIA_URL = 'https://pegasfleet.dev.wippo-it.net/storage/';
export const BASE_MEDIA_URL = 'https://fleet.pegas.md/storage/';

export const USER_COOKIE_NAME = 'user';

export const PRIORITY_COLORS = {
    low: '#3c8dbc',
    medium: 'orange',
    high: 'red'
};

export const COLORS = {
    red: '#e94b4a',
    gray: '#e4e4e4',
    black: '#090909'
};