import {ACTION_LOGIN} from './actions';

const initialState = {
    userData: ''
};

const userReducers = (state, action) => {
    switch (action.type) {
        case ACTION_LOGIN:
            return Object.assign({}, state, {userData: action.payload});

        default:
            return initialState;
    }
};

export default userReducers;