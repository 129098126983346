import React, {Component} from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { createStyles, withStyles } from '@material-ui/core/styles';
import {PRIORITY_COLORS} from '../constants';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {openEditCustomTaskDialog, showConfirmation, removeTaskById, axiosFetchAllTasks} from '../store/common/actions';

const styles = theme => createStyles({
    card: {
        marginBottom: 20,
        textAlign: 'left',
        paddingRight: 60,
        position: 'relative',
        borderRadius: 0,
        fontSize: 16,
    },

    cardPriority: {
        display: 'inline-block',
        width: 10,
        height: 10,
        borderRadius: '50%',
        marginRight: 10,

        ['&.high']: {
            backgroundColor: PRIORITY_COLORS.high
        },

        ['&.medium']: {
            backgroundColor: PRIORITY_COLORS.medium
        },

        ['&.low']: {
            backgroundColor: PRIORITY_COLORS.low
        }
    },

    title: {
        fontSize: 16,
        fontWeight: 'bold',
        marginBottom: 0
    },

    comment: {
        marginTop: 15,
        fontSize: 15,
        paddingLeft: 20,
        color: theme.palette.secondary.main,
    },

    vert_btn: {
        position: 'absolute',
        top: 0,
        right: 0
    },
    cardUpdated: {
        color: theme.palette.secondary.main,
        display: 'block',
        fontSize: 12,
        paddingLeft: 20,
        fontWeight: 'normal'
    }
});


class TaskList extends Component {
    state = {
        anchorEl: null,
        id: null
    };

    goTo = (e,path) => {
        this.props.history.push(path)
    };

    deleteTask = async () => {
        const removed = await removeTaskById(this.state.id);

        if (removed) {
            this.setState({anchorEl: null});
            this.props.axiosFetchAllTasks({record_type: 'custom'}, true);
        }
    };

    render() {
        const {classes, lng, tasks, openEditCustomTaskDialog, showConfirmation} = this.props;
        const {anchorEl} = this.state;

        return (
            <div>
                {tasks.map(task => {
                    return (
                        <Card key={task.id} className={classes.card}>
                            <CardContent>
                                <Typography variant="h5" component="h2" className={classes.title}>
                                    <span className={classes.cardPriority+' '+task.priority} />{task.text}-{task.vehicle_registration_number}
                                    <span className={classes.cardUpdated}>{task.due_date}</span>
                                </Typography>

                                {task.comment !== null &&
                                    <Typography className={classes.comment}>
                                        {task.comment}
                                    </Typography>
                                }
                            </CardContent>

                            <IconButton
                                aria-label={"more="+task.id}
                                aria-controls={"long-menu-"+task.id}
                                aria-haspopup="true"
                                className={classes.vert_btn}
                                onClick={(e) => this.setState({anchorEl: e.currentTarget, id: task.id})}
                            >
                                <MoreVertIcon />
                            </IconButton>
                        </Card>
                    )
                })}

                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={(e) => this.setState({anchorEl: null})}
                >
                    <MenuItem onClick={() => showConfirmation(true, lng.delete_task, lng.are_you_sure, this.deleteTask)}>
                        {lng.delete}
                    </MenuItem>
                    {/*<MenuItem onClick={() => openEditCustomTaskDialog(this.state.id)}>*/}
                        {/*{lng.edit}*/}
                    {/*</MenuItem>*/}
                </Menu>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        lng: state.common.lng,
        tasks: state.common.customTasksList
    }
};

const mapDispatchToProps = {
    openEditCustomTaskDialog,
    showConfirmation,
    axiosFetchAllTasks
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TaskList)))