import React, {Component} from 'react';
import {connect} from 'react-redux';
import { createStyles, withStyles } from '@material-ui/core/styles';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import {
    axiosGetRegistrationNumbers,
    openEditCustomTaskDialog,
    selectRegistrationNumber,
    axiosCreateCustomTask,
    displayMessageBar,
    axiosFetchAllTasks,
    axiosGetTaskById
} from '../../store/common/actions';
import MakePhoto from "../MakePhoto";
import Button from "@material-ui/core/Button";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormLabel from "@material-ui/core/FormLabel";
import Radio from "@material-ui/core/Radio";
import TextField from "@material-ui/core/TextField";
import {PRIORITY_COLORS} from "../../constants";
import RegistrationNumber from "../filter/RegistrationNumber";
import FormHelperText from "@material-ui/core/FormHelperText";
import {withRouter} from 'react-router-dom';
import CircularProgress from "@material-ui/core/CircularProgress";

const styles = theme => createStyles({
    dialog: {
        width: '100%',
        margin: 30,
        paddingTop: 15,
        paddingBottom: 15,
        textAlign: 'center'
    },

    formGroup: {
        width: '100%',
        marginBottom: 15
    },

    priorityGroup: {
        flexWrap: 'nowrap',
        flexDirection: 'initial',
        alignItems: 'center',
        margin: '0 0 15px'
    },

    low: {
        color: PRIORITY_COLORS.low,

        '&:checked': {
            color: PRIORITY_COLORS.low,
        },
    },

    medium: {
        color: PRIORITY_COLORS.medium,

        '&:checked': {
            color: PRIORITY_COLORS.medium,
        },
    },

    high: {
        color: PRIORITY_COLORS.high,

        '&:checked': {
            color: PRIORITY_COLORS.high,
        },
    },

    save: {
        margin: '30px 10px 0',
    },
});

class Edit extends Component {
    state = {
        error: {
            reg_numbers: false,
            due_date: false,
            comment: false,
            priority: false
        },

        sendingPreloader: false,

        loadPreloader: false
    };

    validation = () => {
        let error = this.state.error;

        const reg_numbers = this.props.selected_reg_numbers.map(n => {
            return n.value
        });
        const due_date = document.getElementById('due_date').value;
        const comment = document.getElementById('comment').value;
        const priority = document.querySelector('input[name="priority"]:checked') ? document.querySelector('input[name="priority"]:checked').value : '';

        if (reg_numbers.length === 0) {
            error.reg_numbers = true;
        } else {
            error.reg_numbers = false;
        }

        if (due_date.length === 0) {
            error.due_date = true;
        } else {
            error.due_date = false;
        }

        if (comment.length === 0) {
            error.comment = true;
        } else {
            error.comment = false;
        }

        if (priority.length === 0) {
            error.priority = true;
        } else {
            error.priority = false;
        }

        this.setState({error})
    };

    submit = async (e) => {
        e.preventDefault();

        this.validation();

        const reg_numbers = this.props.selected_reg_numbers.map(n => {
            return n.value
        });
        const vehicle_id = reg_numbers.join(',');
        const due_date = document.getElementById('due_date').value;
        const text = document.getElementById('comment').value;
        const priority = document.querySelector('input[name="priority"]:checked') ? document.querySelector('input[name="priority"]:checked').value : '';

        const images = this.props.photos.map(p => {
            return p.file
        });

        if (due_date.length > 0 && text.length > 0 && priority.length > 0 && vehicle_id.length > 0) {
            this.setState({sendingPreloader: true});
            const edited = await axiosCreateCustomTask({vehicle_id, due_date, text, priority, images});

            if (edited) {
                this.props.axiosFetchAllTasks({record_type: 'custom'});
                this.props.openEditCustomTaskDialog(false);
                this.props.displayMessageBar('The task has been edited', 'success');
            } else {
                this.setState({sendingPreloader: false});
                this.props.displayMessageBar('An error has occurred', 'error');
            }
        }
    };

    loadTask = async () => {
        this.setState({loadPreloader: true});
        await this.props.axiosGetTaskById(this.props.task_id);
        this.setState({loadPreloader: false});
    };

    componentDidUpdate(prevProps) {
        if ((prevProps.task_id !== this.props.task_id) && this.props.task_id) {
            this.loadTask();
        }
    }

    render() {
        const {task_id, lng, openEditCustomTaskDialog, classes, task, reg_numbers} = this.props;
        const {error, sendingPreloader, loadPreloader} = this.state;


        const default_due_date_index = reg_numbers.findIndex(n => n.id === task.vehicle_id );
        const default_due_date_value = (default_due_date_index !== -1) ? {value: reg_numbers[default_due_date_index].id, label: reg_numbers[default_due_date_index].number} : {};



        if (loadPreloader) {
            return <div style={{textAlign: 'center'}}>
                <CircularProgress disableShrink />
            </div>
        }

        return (
            <Dialog
                open={Boolean(task_id)}
                onClose={() => openEditCustomTaskDialog(null)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                PaperProps={{classes: {root: classes.dialog}}}
            >
                <DialogTitle>{lng.edit_the_task}</DialogTitle>
                <DialogContent>
                    <form onSubmit={this.submit}>
                        <div className={classes.formGroup}>
                            <RegistrationNumber defaultValue={default_due_date_value} isMulti={false} />
                        </div>

                        {error.reg_numbers &&
                        <FormHelperText style={{color: 'red', margin: '5px 0 15px'}}>
                            Is required
                        </FormHelperText>
                        }

                        <TextField
                            name="due_date"
                            label="Date"
                            type="date"
                            id="due_date"
                            onChange={this.validation}
                            className={classes.formGroup}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />

                        {error.due_date &&
                        <FormHelperText style={{color: 'red', margin: '-10px 0 15px'}}>
                            Is required
                        </FormHelperText>
                        }

                        <TextField
                            name="comment"
                            id="comment"
                            label="Comment"
                            onChange={this.validation}
                            multiline
                            className={classes.formGroup}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />

                        {error.comment &&
                        <FormHelperText style={{color: 'red', margin: '-10px 0 15px'}}>
                            Is required
                        </FormHelperText>
                        }

                        <RadioGroup
                            aria-label="priority"
                            name="priority"
                            onChange={this.validation}
                            className={classes.priorityGroup}
                        >
                            <FormLabel component="legend">{lng.priority}:</FormLabel>
                            <Radio color="default" value="low" className={classes.low} />
                            <Radio color="default" value="medium" className={classes.medium} />
                            <Radio color="default" value="high" className={classes.high} />
                        </RadioGroup>

                        {error.priority &&
                        <FormHelperText style={{color: 'red', margin: '-10px 0 15px'}}>
                            Is required
                        </FormHelperText>
                        }

                        <MakePhoto/>

                        {sendingPreloader &&
                        <div>
                            <br/>
                            <CircularProgress disableShrink /> <br/>
                        </div>
                        }

                        <Button type="submit" variant="contained" color="primary" className={classes.save}>
                            {lng.save}
                        </Button>

                        <Button className={classes.save} onClick={() => openEditCustomTaskDialog(false, {})}>
                            {lng.close}
                        </Button>
                    </form>
                </DialogContent>
            </Dialog>
        )
    }
}

const mapStateToProps = state => {
    return {
        lng: state.common.lng,
        task_id: state.common.open_edit_custom_task_dialog,
        selected_reg_numbers: state.common.selected_reg_number,
        reg_numbers: state.common.reg_numbers,
        photos: state.common.photos,
        tasks: state.common.tasksList,
        task: state.common.detailedTask,
    }
};

const mapDispatchToProps = {
    openEditCustomTaskDialog,
    axiosGetRegistrationNumbers,
    selectRegistrationNumber,
    displayMessageBar,
    axiosFetchAllTasks,
    axiosGetTaskById
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Edit)))