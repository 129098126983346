import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    setToolbarTitle,
    axiosFetchChecklist, fetchChecklist, axiosCreateChecklistReport,
    showPreloader, hidePreloader, displayMessageBar, setPhotos
} from '../../store/common/actions';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import TextField from "@material-ui/core/TextField";
import MakePhoto from "../../layouts/MakePhotoRequest";
import Button from '@material-ui/core/Button';
import FormLabel from '@material-ui/core/FormLabel';
import Preloader from "../../layouts/Preloader";
import {PRIORITY_COLORS, COLORS} from "../../constants";
import FormHelperText from "@material-ui/core/FormHelperText";
import CircularProgress from '@material-ui/core/CircularProgress';
import queryString from 'query-string';

const styles = theme => createStyles({
    item: {
        padding: '5px 15px',
        textAlign: 'left',
        marginBottom: 20
    },

    kilometers: {
        marginBottom: 20,
        width: '100%'
    },

    kilometersLabel: {
        color: theme.palette.primary.main,
        fontSize: 16,
        transform: 'scale(1)',
        fontWeight: 'bold',
        fontFamily: 'inherit'
    },

    title: {
        fontWeight: 'bold',
        marginBottom: 15,
        marginTop: 9
    },

    label: {
        width: 'auto',
        marginRight: 30
    },

    priority_label: {
        fontSize: 12
    },

    formGroup: {
        width: '100%',
        marginBottom: 15
    },

    group: {
        flexWrap: 'nowrap',
        flexDirection: 'row',
        justifyContent: 'center'
    },

    dialog: {
        width: '100%',
        margin: 30,
        paddingTop: 15,
        paddingBottom: 15,
        textAlign: 'center'
    },

    textarea: {
        width: '100%',
        minHeight: 100
    },

    save: {
        margin: '30px 10px 0',
        color: COLORS.black,
        backgroundColor: COLORS.gray,
        minWidth: 180,
        height: 40,

        ['&:hover, &:focus']: {
            color: COLORS.black,
            backgroundColor: COLORS.gray,
        }
    },

    low: {
        color: PRIORITY_COLORS.low,

        '&:checked': {
            color: PRIORITY_COLORS.low,
        },
    },

    medium: {
        color: PRIORITY_COLORS.medium,

        '&:checked': {
            color: PRIORITY_COLORS.medium,
        },
    },

    high: {
        color: PRIORITY_COLORS.high,

        '&:checked': {
            color: PRIORITY_COLORS.high,
        },
    },

    finish: {
        margin: '20px 0 40px',
        padding: '12px 35px',
        color: COLORS.black,
        backgroundColor: COLORS.gray,
        border: 0,
        outline: 'none',
        borderRadius: 4
    },

    priorityGroup: {
        flexWrap: 'nowrap',
        flexDirection: 'initial',
        alignItems: 'center',
        margin: '0 0 15px'
    },

    page: {
        textAlign: 'center'
    }
});

class TaskSingle_old extends Component {
    state = {
        open: false,
        imagesOpen: false,

        sendingPreloader: false,

        checklistIndex: null,

        kilometers: null,

        errors: {
            due_date: {
                error: false,
                msg: 'Обязательно для заполнения',
            },

            comment: {
                error: false,
                msg: 'Обязательно для заполнения',
            },

            priority: {
                error: false,
                msg: 'Обязательно для заполнения',
            },

            images: {
                error: false,
                msg: 'Загрузите хотя бы одно изображение',
            },
        },
        update: true
    };

    loadChecklist = async () => {
        let params = queryString.parse(this.props.location.search);
        const current_kilometers = (!isNaN(Number(params.mileage)) && (params.mileage !== null || params.mileage !== undefined)) ? parseInt(params.mileage) : 0;

        this.props.fetchChecklist([]);
        this.props.showPreloader();
        const checklist_id = this.props.match.params.checklist_ids;
        await this.props.axiosFetchChecklist({checklist_id});
        this.props.hidePreloader();
        this.setState({kilometers:current_kilometers})
    };

    componentDidMount() {
        this.props.setToolbarTitle(this.props.lng.checklist);
        this.loadChecklist();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.photos.length !== this.props.photos.length && this.state.open) {
            const validation = this.dialogValidate();
            this.setState(validation)
        }
    }

    answer = (e, index, image_required) => {
        const type = e.currentTarget.getAttribute('data-type');
        let errors = this.state.errors;

        errors.due_date.error = false;
        errors.comment.error = false;
        errors.priority.error = false;
        errors.images.error = false;

        let checklist = this.props.checklist;

        if (type === 'fail') {
            this.setState({
                open: true,
                checklistIndex: index
            })
        } else if (type === 'success' && image_required === 'yes') {
            this.setState({
                imagesOpen: true,
                checklistIndex: index
            })
        } else {
            checklist[index].checked[type] = true;

            if (type === 'success') {
                checklist[index].checked['fail'] = false;
            } else if (type === 'fail') {
                checklist[index].checked['success'] = false;
            }

            this.props.fetchChecklist([]);
            this.props.fetchChecklist(checklist);

            this.setState({errors})
        }
    };

    dialogClose = () => {
        let errors = this.state.errors;

        errors.due_date.error = false;
        errors.comment.error = false;
        errors.priority.error = false;
        errors.images.error = false;

        this.setState({open: false, imagesOpen: false, errors})
    };

    addPhotos = (e) => {
        e.preventDefault();

        const index = this.state.checklistIndex;
        let checklist = this.props.checklist;

        if (this.props.photos.length > 0) {
            const images = this.props.photos.map(img => {
                return img.origin
            });

            checklist[index].additional.images = images;
            checklist[index].checked.success = true;
            checklist[index].checked.fail = false;

            this.props.setPhotos([]);
            this.props.fetchChecklist([]);
            this.props.fetchChecklist(checklist);

            this.dialogClose();
        } else {
            this.props.displayMessageBar('Загрузите хотя бы одно изображение', 'error');
        }
    };

    addAdditional = (values) => {
        const index = this.state.checklistIndex;
        let checklist = this.props.checklist;

        // const due_date = values.due_date;
        const comment = values.comment;
        const priority = values.priority;

        if (index !== null && checklist.length > 0) {
            // checklist[index].additional.due_date = due_date;
            checklist[index].additional.comment = comment;
            checklist[index].additional.priority = priority;

            if (this.props.photos.length > 0) {
                const images = this.props.photos.map(img => {
                    return img.origin
                });

                checklist[index].additional.images = images;
            }

            checklist[index].checked.fail = true;
            checklist[index].checked.success = false;

            this.props.setPhotos([]);
            this.props.fetchChecklist([]);
            this.props.fetchChecklist(checklist);

            this.dialogClose();
        }
    };

    finish = async (e) => {
        e.preventDefault();

        let error = false;
        let error_msg = '';
        let items = [];
        let items_props = {};
        const mileage = document.getElementById('kilometers').value;
        const vehicle_id = this.props.match.params.vehicle_id;
        const task_id = this.props.match.params.task_id;

        let formData = new FormData();

        this.props.checklist.map(c => {
            if (!c.checked.success && !c.checked.fail) {
                error = true;
                error_msg = '"'+c.name+'" '+this.props.lng.is_required;
            }

            items_props = {
                item_id: c.id,
                checked: c.checked.success ? 1 : 0,
            };

            if (c.checked.fail) {
                items_props.comment = c.additional.comment;
                // items_props.due_date = c.additional.due_date;
                items_props.priority = c.additional.priority;
            }

            if (c.additional.images.length > 0) {
                let images = [];

                c.additional.images.map((image) => {
                    images.push(image)
                });

                items_props.images = images;
            }

            items.push(items_props)
        });

        items = JSON.stringify(items);


        if (mileage <= this.state.kilometers) {
            error = true;
            error_msg = this.props.lng.small_mileage;
        }

        if (mileage.length <= 0) {
            error = true;
            error_msg = '"'+this.props.lng.kilometers+'" '+this.props.lng.is_required;
        }


        if (mileage.length > 0 && mileage <= this.state.kilometers) {
            error = true;
            error_msg = this.props.lng.small_mileage;
        }

        if (error) {
            this.props.displayMessageBar(error_msg, 'error');
            return false;
        }

        formData.append('vehicle_id', vehicle_id);
        if (task_id !== 'null' && task_id !== null) {
            formData.append('task_id', task_id);
        }
        formData.append('mileage', mileage);
        formData.append('items', items);

        this.setState({sendingPreloader: true});
        const send = await axiosCreateChecklistReport(formData);

        if (send) {
            this.props.history.push('/');
            this.props.displayMessageBar('Отчет отправлен', 'success');
        } else {
            this.setState({sendingPreloader: false});
            this.props.displayMessageBar('Произошла ошибка', 'error');
        }
    };

    dialogValidate = () => {
        let errors = this.state.errors;
        let update = [];

        // const due_date = document.getElementById('due_date').value;
        const comment = document.getElementById('comment').value;
        let priority = document.querySelector('input[name="priority"]:checked');
        priority = priority ? priority.value : '';

        // if (due_date.length <= 0) {
        //     update.push(false);
        //     errors.due_date.error = true;
        // } else {
        //     errors.due_date.error = false;
        // }

        if (comment.length <= 0) {
            update.push(false);
            errors.comment.error = true
        } else {
            // update = true;
            errors.comment.error = false;
        }

        if (priority.length <= 0) {
            update.push(false);
            errors.priority.error = true
        } else {
            // update = true;
            errors.priority.error = false;
        }

        if (this.props.photos.length === 0) {
            update.push(false);
            errors.images.error = true
        } else {
            // update = true;
            errors.images.error = false;
        }

        return {update: (update.length === 0), errors}
    };

    validateOnChange = () => {
        const valid = this.dialogValidate();
        this.setState(valid);
    };

    submitDialog = (e) => {
        e.preventDefault();

        const valid = this.dialogValidate();

        if (valid.update) {
            // const due_date = document.getElementById('due_date').value;
            const comment = document.getElementById('comment').value;
            let priority = document.querySelector('input[name="priority"]:checked');
            priority = priority ? priority.value : '';

            this.addAdditional({comment, priority})
        } else {
            this.setState(valid)
        }
    };

    render() {
        const {classes, lng, checklist, preloader} = this.props;
        const {open, imagesOpen, sendingPreloader, errors, kilometers} = this.state;

        return (
            <div className={classes.page}>
                <Preloader />

                {!preloader &&
                <TextField
                    label={lng.kilometers}
                    type="number"
                    id="kilometers"
                    name="kilometers"
                    className={classes.kilometers}
                    InputLabelProps={{
                        shrink: true,
                        classes: {root: classes.kilometersLabel}
                    }}
                />
                }

                <form onSubmit={this.finish}>
                    {checklist.map((c, i) => {
                        return (
                            <Paper key={c.id} className={classes.item}>
                                <p className={classes.title}>
                                    {c.name}
                                </p>

                                <RadioGroup
                                    aria-label="gender"
                                    name={'checklist_'+i}
                                    className={classes.group}
                                    onChange={(e) => this.answer(e, i, c.image_required)}
                                >
                                    <FormControlLabel
                                        className={classes.label}
                                        value="yse"
                                        control={<Radio checked={c.checked.success} inputProps={{'data-type': 'success'}} color="primary" />}
                                        label={lng.yes}
                                    />
                                    <FormControlLabel
                                        className={classes.label}
                                        value="no"
                                        control={<Radio checked={c.checked.fail} inputProps={{'data-type': 'fail'}} color="primary" />}
                                        label={lng.no}
                                    />
                                </RadioGroup>
                            </Paper>
                        )
                    })}

                    {sendingPreloader &&
                    <div>
                        <CircularProgress disableShrink /> <br/>
                    </div>
                    }

                    {!preloader &&
                    <button type="submit" className={classes.finish}>
                        {lng.finish}
                    </button>
                    }

                </form>


                <Dialog
                    open={open}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    PaperProps={{classes: {root: classes.dialog}}}
                    onClose={() => this.dialogClose()}
                >
                    <DialogContent>
                                <form onSubmit={this.submitDialog}>
                                    {/*<TextField*/}
                                        {/*label={lng.date}*/}
                                        {/*type="date"*/}
                                        {/*id="due_date"*/}
                                        {/*className={classes.formGroup}*/}
                                        {/*name="due_date"*/}
                                        {/*onChange={this.validateOnChange}*/}
                                        {/*InputLabelProps={{*/}
                                            {/*shrink: true,*/}
                                        {/*}}*/}
                                    {/*/>*/}

                                    {errors.due_date.error &&
                                        <FormHelperText
                                            style={{color: 'red', margin: '-10px 0 15px'}}>
                                            {errors.due_date.msg}
                                        </FormHelperText>
                                    }

                                    <TextField
                                        id="comment"
                                        name="comment"
                                        onChange={this.validateOnChange}
                                        className={classes.formGroup}
                                        label={lng.comment}
                                        multiline
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />

                                    {errors.comment.error &&
                                    <FormHelperText
                                        style={{color: 'red', margin: '-10px 0 15px'}}>
                                        {errors.comment.msg}
                                    </FormHelperText>
                                    }

                                    <RadioGroup
                                        aria-label="priority"
                                        name="priority"
                                        onChange={this.validateOnChange}
                                        className={classes.priorityGroup}
                                    >
                                        <FormLabel component="legend" className={classes.priority_label}>{lng.priority}:</FormLabel>
                                        <Radio color="default" value="low" className={classes.low} />
                                        <Radio color="default" value="medium" className={classes.medium} />
                                        <Radio color="default" value="high" className={classes.high} />
                                    </RadioGroup>

                                    {errors.priority.error &&
                                    <FormHelperText
                                        style={{color: 'red', margin: '-10px 0 15px'}}>
                                        {errors.priority.msg}
                                    </FormHelperText>
                                    }

                                    <MakePhoto/>

                                    {errors.images.error &&
                                    <FormHelperText
                                        style={{color: 'red', margin: '5px 0 15px', textAlign: 'center'}}>
                                        {errors.images.msg}
                                    </FormHelperText>
                                    }

                                    <Button type="submit" variant="contained" color="primary" className={classes.save}>
                                        {lng.save}
                                    </Button>
                                </form>
                    </DialogContent>
                </Dialog>


                <Dialog
                    open={imagesOpen}
                    PaperProps={{classes: {root: classes.dialog}}}
                    onClose={() => this.dialogClose()}
                >
                    <DialogContent>
                        <form onSubmit={this.addPhotos}>
                            <MakePhoto/>

                            <Button type="submit" variant="contained" color="primary" className={classes.save}>
                                {lng.save}
                            </Button>
                        </form>
                    </DialogContent>
                </Dialog>
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        tasks: state.common.tasksList,
        checklist: state.common.checklist,
        lng: state.common.lng,
        preloader: state.common.showPreloader,
        photos: state.common.photos
    }
};

const mapDispatchToProps = {
    setToolbarTitle,
    axiosFetchChecklist,
    showPreloader,
    hidePreloader,
    fetchChecklist,
    displayMessageBar,
    setPhotos
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(TaskSingle_old));