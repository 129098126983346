import axios from '../../axios';
import cookie from '../../cookie';
import {isJsonString} from '../../scripts';
import {USER_COOKIE_NAME} from '../../constants';

const getToken = () => {
    let user = cookie.getCookie(USER_COOKIE_NAME);
    let token = '';

    if (user && isJsonString(user)) {
        user = JSON.parse(user);
        token = user.token;
    }

    return token;
};


// User

export const LOGIN = 'LOGIN';
export const SET_LOGIN_STATUS = 'SET_LOGIN_STATUS';

// Tasks

export const FETCH_TASKS = 'FETCH_TASKS';
export const FETCH_CUSTOM_TASKS = 'FETCH_CUSTOM_TASKS';
export const FETCH_FILTERED_TASKS = 'FETCH_FILTERED_TASKS';
export const FETCH_CHECKLIST = 'FETCH_CHECKLIST';

// Custom tasks

export const OPEN_CUSTOM_TASK_DIALOG = 'OPEN_CUSTOM_TASK_DIALOG';
export const OPEN_EDIT_CUSTOM_TASK_DIALOG = 'OPEN_EDIT_CUSTOM_TASK_DIALOG';
export const SAVE_CUSTOM_TASK = 'SAVE_CUSTOM_TASK';

// General

export const SHOW_PRELOADER = 'SHOW_PRELOADER';
export const HIDE_PRELOADER = 'HIDE_PRELOADER';
export const SET_TOOLBAR_TITLE = 'SET_TOOLBAR_TITLE';
export const DISPLAY_MESSAGE_BAR = 'DISPLAY_MESSAGE_BAR';
export const SET_PHOTOS = 'SET_PHOTOS';
export const SHOW_CONFIRMATION = 'SHOW_CONFIRMATION';
export const ADD_PHOTO = 'ADD_PHOTO';

// Filter

export const GET_VEHICLE_TYPE = 'GET_VEHICLE_TYPE';
export const SELECT_VEHICLE_TYPE = 'SELECT_VEHICLE_TYPE';
export const GET_VEHICLE_GROUP = 'GET_VEHICLE_GROUP';
export const SELECT_VEHICLE_GROUP = 'SELECT_VEHICLE_GROUP';
export const SET_REGISTRATION_NUMBER = 'SET_REGISTRATION_NUMBER';
export const SELECT_REGISTRATION_NUMBER = 'SELECT_REGISTRATION_NUMBER';

// Login

export function login(user_data) {
    return {type: LOGIN, payload: user_data}
}

export function logout() {
    cookie.deleteCookie(USER_COOKIE_NAME);
    return {type: LOGIN, payload: {}}
}

export function axiosLogin(email, password) {
    return (dispatch) => {
        dispatch(setLoginStatus(null));
        return axios.post('/user/get-token', {email, password})
            .then((response) => {
                if (response.data.errors.length === 0) {
                    cookie.setCookie(USER_COOKIE_NAME, JSON.stringify(response.data.data), 1);
                    dispatch(login(response.data.data));
                    dispatch(setLoginStatus(null));
                } else {
                    dispatch(setLoginStatus(false));
                }
            })
            .catch((error) => {
                console.log('error - user/get-token', error)
            })
    }
}

export function setLoginStatus(status) { // null / true / false
    return {type: SET_LOGIN_STATUS, payload: status}
}

// Tasks

export function fetchTasks(tasks) {
    return {type: FETCH_TASKS, payload: tasks}
}

export function fetchCustomTasks(tasks) {
    return {type: FETCH_CUSTOM_TASKS, payload: tasks}
}

export function fetchFilteredTasks(tasks) {
    return {type: FETCH_FILTERED_TASKS, payload: tasks}
}

export function fetchChecklist(checklist) {
    return {type: FETCH_CHECKLIST, payload: checklist}
}

export function axiosFetchTasks(args = {}) {
    let params = {};

    Object.keys(args).map(key => {
        params[key] = args[key]
    });

    params.token = getToken();

    return (dispatch) => {
        return axios.get('/task/internal-inspection', {params})
            .then((response) => {
                if (response.data) {
                    dispatch(fetchTasks(response.data.data))
                }
            })
            .catch((error) => {
                console.log('error - task/list', error)
            })
    }
}

export function axiosFetchVehicles(args = {}) {
    let params = {};

    Object.keys(args).map(key => {
        params[key] = args[key]
    });

    params.token = getToken();

    return (dispatch) => {
        return axios.get('/vehicle/list?with=last_report,checklists,internal_inspection_task', {params})
            .then((response) => {
                if (response.data) {
                    dispatch(fetchFilteredTasks(response.data.data))
                }
            })
            .catch((error) => {
                console.log('error - vehicle/list', error)
            })
    }
}

export function axiosFetchAllTasks(args = {}, custom = false) {
    let params = {};

    Object.keys(args).map(key => {
        params[key] = args[key]
    });

    params.token = getToken();

    return (dispatch) => {
        return axios.get('/task/list', {params})
            .then((response) => {
                if (response.data) {
                    if (custom) {
                        dispatch(fetchCustomTasks(response.data.data))
                    } else {
                        dispatch(fetchTasks(response.data.data))
                    }
                }
            })
            .catch((error) => {
                console.log('error - task/list', error)
            })
    }
}

export function axiosFetchTasksTest(args = {}) {
    let params = {};

    Object.keys(args).map(key => {
        params[key] = args[key]
    });

    params.token = getToken();

    return axios.get('/task/internal-inspection', {params})
        .then((response) => {
            if (response.data) {
                return response.data.data
            }
        })
        .catch((error) => {
            console.log('error - task/list', error)
            return [];
        })
}

export function axiosFetchChecklist(args = {}) {
    let params = {};

    Object.keys(args).map(key => {
        params[key] = args[key]
    });

    params.token = getToken();

    return (dispatch) => {
        return axios.get('/checklist/items-by-checklists', {params})
            .then((response) => {
                if (response.data) {
                    const result = response.data.data;
                    let filtered = [];

                    result.map(c => {
                            c.items.map(item => {
                                filtered.push({
                                    id: item.id,
                                    name: item.name,
                                    checked: {
                                        success: false,
                                        fail: false,
                                    },
                                    image_required: item.image_mandatory,
                                    additional: {
                                        due_date: '',
                                        comment: '',
                                        priority: '',
                                        images: []
                                    }
                                })
                            })
                    });

                    dispatch(fetchChecklist(filtered))
                }
            })
            .catch((error) => {
                console.log('error - task/list', error)
            })
    }
}

export function axiosCreateChecklistReport(args = {}) {
    let params = args;

    params.append('token', getToken());

    return axios.post('/checklist-report/create', args, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }).then((response) => {
            return response.data.errors.length === 0
        })
        .catch((error) => {
            console.log('error - checklist-report/create', error)
            return false;
        })
}

export function saveCustomTask(task_object) {
    return {type: SAVE_CUSTOM_TASK, payload: task_object}
}

export function axiosGetTaskById(id) {
    const token = getToken();

    return (dispatch) => {
        return axios.get('/task/read', {params: {id, token}})
            .then((response) => {
                if (response.data.errors.length === 0) {
                    dispatch(saveCustomTask(response.data.data))
                }
            }).catch((error) => {
                console.log('error - /task/read', error)
            })
    }

}

// Custom tasks

export function openCustomTaskDialog(open) {
    return {type: OPEN_CUSTOM_TASK_DIALOG, payload: open}
}

export function axiosCreateCustomTask(/*args = {}*/formData) {
    // let formData = new FormData();
    //
    // Object.keys(args).map(key => {
    //     formData.append(key, args[key])
    // });
    //
    formData.append('token',getToken());

    return axios.post('/task/create', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }).then((response) => {
        return response.data.errors.length === 0
    }).catch((error) => {
        console.log('error - checklist-report/create', error)
        return false;
    })
}

export function openEditCustomTaskDialog(task_id) {
    return {type: OPEN_EDIT_CUSTOM_TASK_DIALOG, payload: task_id}
}

export function removeTaskById(id) {
    const token = getToken();
    return axios.get('/task/delete', {params: {id, token}})
        .then((response) => {
            return (response.data.errors.length === 0);
        }).catch((error) => {
            return false
        })
}

// General

export function showPreloader() {
    return {type: SHOW_PRELOADER, payload: true}
}

export function hidePreloader() {
    return {type: HIDE_PRELOADER, payload: false}
}

export function setToolbarTitle(title) {
    return {type: SET_TOOLBAR_TITLE, payload: title}
}

export function displayMessageBar(message, barType) {
    return {type: DISPLAY_MESSAGE_BAR, message, barType}
}

export function setPhotos(photos) {
    return {type: SET_PHOTOS, payload: photos}
}

export function addPhoto(src) {
    return {type: ADD_PHOTO, payload: src}
}

export function showConfirmation(open = true, title = '', message = '', accept = () => {}, cancel = () => {}) {
    return {type: SHOW_CONFIRMATION, open, title, message, accept, cancel}
}

export function axiosSendPhoto(file) {
    let params = new FormData();

    params.append('image',file);

    params.append('token',getToken());

    return axios.post('/image/upload', params)
        .then((response) => { //alert(JSON.stringify(response))
            if (response.data.errors.length === 0) {
                return response.data.data.path;
            } else {
                return ''
            }
        })
        .catch((error) => {
            return JSON.stringify(error)
        })
}

// Filter

export function getVehicleType(types) {
    return {
        type: GET_VEHICLE_TYPE,
        payload: types
    }
}

export function selectVehicleType(type) {
    return {
        type: SELECT_VEHICLE_TYPE,
        payload: type
    }
}

export function getVehicleGroup(groups) {
    return {
        type: GET_VEHICLE_GROUP,
        payload: groups
    }
}

export function selectVehicleGroup(group) {
    return {
        type: SELECT_VEHICLE_GROUP,
        payload: group
    }
}

export function axiosGetVehicleType(args = {}) {
    let params = {};
    let types = [];

    Object.keys(args).map(key => {
        params[key] = args[key]
    });

    params.token = getToken();

    return (dispatch) => {
        return axios.get('/vehicle-type/list', {params})
            .then((response) => {
                if (response.data) {
                    types = response.data.data.map(type => {
                       return {
                           id: type.id,
                           name: type.name,
                           checked: false
                       }
                    });
                    dispatch(getVehicleType(types))
                }
            })
            .catch((error) => {
                console.log('error - /vehicle-type/list', error)
            })
    }
}

export function axiosGetVehicleGroup(args = {}) {
    let params = {};
    let groups = [];

    Object.keys(args).map(key => {
        params[key] = args[key]
    });

    params.token = getToken();

    return (dispatch) => {
        return axios.get('/vehicle-group/list', {params})
            .then((response) => {
                if (response.data) {
                    groups = response.data.data.map(group => {
                        return {
                            id: group.id,
                            name: group.name,
                            checked: false
                        }
                    });
                    dispatch(getVehicleGroup(groups))
                }
            })
            .catch((error) => {
                console.log('error - /vehicle-type/list', error)
            })
    }
}

export function axiosGetRegistrationNumbers(args = {}) {
    let params = {};
    let numbers = [];

    Object.keys(args).map(key => {
        params[key] = args[key]
    });

    params.token = getToken();

    return (dispatch) => {
        return axios.get('/vehicle/list', {params})
            .then((response) => {
                if (response.data) {
                    numbers = response.data.data.map(v => {
                        return {
                            id: v.id,
                            number: v.registration_number
                        }
                    });
                    dispatch(setRegistrationNumbers(numbers))
                }
            })
            .catch((error) => {
                console.log('error - /vehicle-type/list', error)
            })
    }
}

export function setRegistrationNumbers(numbers) {
    return {type: SET_REGISTRATION_NUMBER, payload: numbers}
}

export function selectRegistrationNumber(number) {
    return {
        type: SELECT_REGISTRATION_NUMBER,
        payload: number
    }
}