export function dateFormat(date) {
    const new_date = new Date(date.replace(' ', 'T'));
    const year = new_date.getFullYear();
    const month = ( (new_date.getMonth() + 1) < 9 ) ? '0'+(new_date.getMonth() + 1) : (new_date.getMonth() + 1);
    const day = ( new_date.getDate() < 9 ) ? '0'+new_date.getDate() : new_date.getDate();
    const hour = ( new_date.getHours() < 9 ) ? '0'+new_date.getHours() : new_date.getHours();
    const minutes = ( new_date.getMinutes() < 9 ) ? '0'+new_date.getMinutes() : new_date.getMinutes();

    return day + '.' + month + '.' + year /*+ ' ' + hour + ':' + minutes*/;
};

export function getUrlParameter(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    let regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    let results = regex.exec(window.location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

export function reduceImageSizes(base64, callback, maxWidth, maxHeight) {
    if (typeof maxWidth === 'undefined') {
        maxWidth = 1200;
    }

    if (typeof maxHeight === 'undefined') {
        maxHeight = 1200;
    }

    const img = new Image();
    img.src = base64;

    img.onload = () => {
        let width = img.width;
        let height = img.height;

        if (width > maxWidth) {
            height = (maxWidth * height) / width;
            width = maxWidth;
        }

        if (height > maxHeight) {
            width = (maxHeight * width) / height;
            height = maxHeight;
        }

        let elem = document.createElement('canvas');
        elem.width = width;
        elem.height = height;

        const ctx = elem.getContext('2d');

        ctx.drawImage(img, 0, 0, width, height);

        let base64 = elem.toDataURL();

        callback(base64);
    };
}

export function ifIsTarget() {
    document.body.addEventListener('click', (e) => {
        if (
            e.target.parentNode !== null && !e.target.parentNode.classList.contains('post--edit')
        ) {
            const edit_post = document.getElementsByClassName('post--edit');
            Array.from(edit_post).forEach(function(element) {
                element.classList.remove('open');
            });
        }
    })
}

export function maxLength(e, length = 20) {
    const keys = [8, 37, 39,46];
    if (e.currentTarget.value.length >= length && !keys.includes(e.keyCode)) {
        e.preventDefault();
    }
}

export function excerpt(text, length = 10) {
    let result = text;

    if (typeof text === "string" && text.length > length && length > 0) {
        result = text.slice(0, length)+'...';
    }

    return result;
}


export function isJsonString(str) {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}