import axios from '../../axios';

export const FETCH_TASKS = 'FETCH_TASKS';

export function fetchTasks(tasks) {
    return {type: FETCH_TASKS, payload: tasks}
}

export function axiosFetchTasks(args = {}) {
    let params = {};

    Object.keys(args).map(key => {
        params[key] = args[key]
    });

    return (dispatch) => {
        return axios.get('/task/list', {params})
            .then((response) => {
                if (response.data) {
                    dispatch(fetchTasks(response.data.data))
                }
            })
            .catch((error) => {
                console.log('error - task/list', error)
            })
    }
}

export function axiosFetchTasksTest(args = {}) {
    let params = {};

    Object.keys(args).map(key => {
        params[key] = args[key]
    });

    return axios.get('/task/list', {params})
        .then((response) => {
            if (response.data) {
                return response.data.data
            }
        })
        .catch((error) => {
            console.log('error - task/list', error)
            return [];
        })
}