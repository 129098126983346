import React, {Component} from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import {axiosLogin} from '../store/common/actions';
import {COLORS} from "../constants";

const SignInSchema = Yup.object().shape({
    password: Yup.string()
        // .min(2, 'Слишком коротко!')
        // .max(20, 'Too Long!')
        .required('Обязательно для заполнения'),
    email: Yup.string()
        .email('Неверный адрес эл. почты')
        .required('Обязательно для заполнения'),
});

const styles = theme => createStyles({
    '@global': {
        body: {
            backgroundColor: theme.palette.common.white,
        },
    },
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center'
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
        color: COLORS.black,
        backgroundColor: COLORS.gray,
        minWidth: 180,
        height: 40,

        ['&:hover']: {
            color: '#fff',
            backgroundColor: COLORS.black,
        }
    },
    logo: {
        color: theme.palette.primary.main,
        fontWeight: 'normal',
        fontFamily: 'Source Sans Pro, sans-serif',

        ['& span']: {
            fontWeight: 'bold'
        }
    }
});

class Login extends Component {

    state = {
        email: '',
        password: ''
    };

    submit = () => {

    };

    render() {
        const {classes, lng, login} = this.props;

        return (
            <Container component="main" maxWidth="xs">
                <div className={classes.paper}>
                    <h1 className={classes.logo}><span>Pegas</span>Fleet</h1>
                    <Formik
                        initialValues={{
                            email: '',
                            password: ''
                        }}
                        validationSchema={SignInSchema}
                        onSubmit={values => {
                            login(values.email, values.password)
                        }}
                    >
                        {({ errors, touched }) => (
                            <Form className={classes.form} autocomplete="off">
                                <Field
                                    name="email"
                                    lng={{label: lng.email_address, required: lng.field_required}}
                                    component={CustomEmailInput}
                                />

                                <Field
                                    name="password"
                                    lng={{label: lng.password, required: lng.field_required}}
                                    component={CustomPasswordInput}
                                />

                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                >
                                    {lng.sign_in}
                                </Button>
                            </Form>
                        )}
                    </Formik>
                </div>
            </Container>
        );
    }
}

const CustomEmailInput = ({field, form: { touched, errors }, ...props}) => (
    <div>
        <TextField
            {...field }
            variant="outlined"
            margin="normal"
            fullWidth
            id="email"
            label={props.lng.label}
            autoComplete="off"
            InputLabelProps={{
                shrink: true
            }}
            {...field}
            {...props}
            error={(touched[field.name] && errors[field.name])}
        />
        {touched[field.name] && errors[field.name] && <FormHelperText style={{color: 'red'}}>{errors[field.name]}</FormHelperText>}
    </div>
);

const CustomPasswordInput = ({field, form: { touched, errors }, ...props}) => (
    <div>
        <TextField
            variant="outlined"
            margin="normal"
            fullWidth
            label={props.lng.label}
            type="password"
            id="password"
            autoComplete="off"
            InputLabelProps={{
                shrink: true
            }}
            {...field}
            {...props}
            error={(touched[field.name] && errors[field.name])}
        />
        {touched[field.name] && errors[field.name] && <FormHelperText style={{color: 'red'}}>{errors[field.name]}</FormHelperText>}
    </div>
);

Login.propTypes = {
    login: PropTypes.func
};

export default withStyles(styles)(Login);