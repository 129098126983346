import React, {Component} from 'react';
import { createStyles, withStyles } from '@material-ui/core/styles';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import FormatListNumbered from '@material-ui/icons/FormatListNumbered';
import FilterList from '@material-ui/icons/FilterList';
import AddIcon from '@material-ui/icons/Add';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon  from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withRouter, matchPath } from "react-router";
import DashboardIndex from "../pages/dash";
import TaskSingle from "../pages/dash/TaskSingle";
import AddDefect from "../pages/dash/AddDefect";
import Filter from "../pages/dash/Filter";
import {Switch, Route} from 'react-router-dom';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {logout, displayMessageBar, showConfirmation} from '../store/common/actions';
import {COLORS} from '../constants';

const styles = theme => createStyles({
    '@global': {
        body: {
            paddingBottom: 50,

            ['& button']: {
                textTransform: 'none !important',
                boxShadow: 'none !important',
            }
        },
    },
    navigation: {
        position: 'fixed',
        left: 0,
        bottom: 0,
        zIndex: 1100,
        width: '100%',
        boxShadow: '0 0 8px 0 rgba(0,0,0,.15)',
        height: 50,

        ['& button']: {
            outline: 'none'
        }
    },

    toolBarRoot: {
        marginBottom: 20
    },

    toolBar: {
        justifyContent: 'space-between',
        backgroundColor: '#e94b4a'
    },

    toolBarLeft: {
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        width: '100%'
    },

    toolBarTitle: {
        lineHeight: 1,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        padding: '5px 3px 5px 15px',
        whiteSpace: 'nowrap',
        flexBasis: '100%',
        maxWidth: '100%',
        textAlign: 'right',
        fontWeight: 'bold'
    },

    bottomButtons: {
        paddingTop: '13px !important',
        color: 'rgba(0,0,0,0.5)',

        ['&.active']: {
            color: COLORS.red,
        }
    },

    container: {
        padding: '0 15px'
    },

    logo: {
        color: '#fff',
        fontFamily: 'Source Sans Pro, sans-serif'
    }
});

class Dashboard extends Component {
    state = {
        open: false
    };

    isActivePath = (path) => {
        const match = matchPath(this.props.location.pathname, {
            path: path,
            exact: true,
            strict: false
        });

        return (match !== null)
    };

    activeClass = (path) => {
        return this.isActivePath(path) ? ' active' : '';
    };

    logout = () => {
        this.props.logout();
    };

    render() {
        const {classes, lng, showConfirmation} = this.props;
        const {anchorEl} = this.state;

        return (
            <div>
                <AppBar position="static" className={classes.toolBarRoot}>
                    <Toolbar variant="dense" className={classes.toolBar}>
                        <span className={classes.logo} onClick={() => this.props.history.push('/')}><b>Pegas</b>Fleet</span>

                        <span className={classes.toolBarTitle}>{this.props.title}</span>

                        <div>
                            <IconButton
                                edge="end"
                                className={classes.menuButton}
                                color="inherit"
                                onClick={(e) => this.setState({anchorEl: e.currentTarget})}
                                aria-label="menu">
                                <MoreVertIcon  />
                            </IconButton>
                            <Menu
                                id="long-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={Boolean(anchorEl)}
                                getContentAnchorEl={null}
                                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                                transformOrigin={{ vertical: "top", horizontal: "center" }}
                                onClose={(e) => this.setState({anchorEl: ''})}
                            >
                                <MenuItem onClick={() => showConfirmation(true, lng.logout, lng.are_you_sure, this.logout)}>
                                    {lng.logout}
                                </MenuItem>
                            </Menu>
                        </div>
                    </Toolbar>
                </AppBar>

                <div className={classes.container}>
                    <Switch>
                        <Route path='/add-defect' component={AddDefect}/>
                        <Route path='/filter' component={Filter}/>
                        <Route path='/task/:task_id/checklist/:checklist_ids/vehicle/:vehicle_id' component={TaskSingle}/>
                        <Route path='/' component={DashboardIndex}/>
                    </Switch>
                </div>

                <BottomNavigation className={classes.navigation}>
                    <BottomNavigationAction
                        label="Tasks"
                        className={classes.bottomButtons+this.activeClass('/')}
                        onClick={() => this.props.history.push('/')}
                        icon={<FormatListNumbered />}
                    />
                    <BottomNavigationAction
                        label="Filter"
                        className={classes.bottomButtons+this.activeClass('/filter')}
                        onClick={() => this.props.history.push('/filter')}
                        icon={<FilterList />}
                    />
                    <BottomNavigationAction
                        label="Add"
                        className={classes.bottomButtons+this.activeClass('/add-defect')}
                        onClick={() => this.props.history.push('/add-defect')}
                        icon={<AddIcon />} />
                </BottomNavigation>
            </div>
        );
    }
}

Dashboard.propTypes = {
    title: PropTypes.string
};

const mapStateToProps = state => {
    return {
        lng: state.common.lng,
        title: state.common.toolBarTitle
    }
};

const mapDispatchToProps = {
    logout,
    displayMessageBar,
    showConfirmation
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Dashboard)));