import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {HashRouter} from "react-router-dom";
import {Provider} from 'react-redux';
import {createStore, applyMiddleware, compose} from 'redux';
import {rootReducer} from './store/reducers';
import thunk from "redux-thunk";
import {createMuiTheme} from '@material-ui/core/styles';
import {ThemeProvider} from '@material-ui/styles';
import cookie from './cookie';
import {isJsonString} from './scripts';
import {login} from './store/common/actions';
import {USER_COOKIE_NAME} from './constants';

const devTools = window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__();
const middlewares = [
    thunk,
    // devTools
];
const store = createStore(rootReducer, applyMiddleware(...middlewares));

const theme = createMuiTheme({
    palette: {
        primary: {
            main: 'rgba(0,0,0,1)'
        },
        secondary: {
            main: 'rgba(0,0,0,.5)'
        },
    },
});

const user = cookie.getCookie(USER_COOKIE_NAME);
if (user && user.length > 0 && isJsonString(user)) {
    store.dispatch(login(JSON.parse(user)));
}

ReactDOM.render(
    <ThemeProvider theme={theme}>
        <Provider store={store}>
            <HashRouter>
                <App />
            </HashRouter>
        </Provider>
    </ThemeProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
