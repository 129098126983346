import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    getVehicleGroup,
    selectVehicleGroup,
    axiosGetVehicleGroup,
} from "../../store/common/actions";
import CarIcon from "@material-ui/icons/DirectionsCar";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import ClearIcon from "@material-ui/icons/Clear";
import Paper from "@material-ui/core/Paper";
import { createStyles, withStyles } from '@material-ui/core/styles';
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";

const styles = theme => createStyles({
    choose: {
        display: 'flex',
        padding: '0 0 0 15px',
        marginBottom: 20,
        alignItems: 'center',
        minHeight: 48,
    },

    name: {
        width: '100%',
        textAlign: 'left',
        padding: '0 15px',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    },

    clear: {
        color: '#f00'
    },

    dialog: {
        width: '100%',
        margin: 30
    },

    dialog_item: {
        padding: '5px 10px',
        margin: '5px 0',

        ['&.selected']: {
            backgroundColor: '#f1f1f1'
        }
    }
});


class ChooseVehicle extends Component {
    state = {
        open: false
    };

    selectGroup = (e, type_object) => {
        let current_groups= this.props.selected_groups;

        if (e.currentTarget.checked) {
            current_groups.push(type_object);
            this.checked(true, type_object);
        } else {
            current_groups = current_groups.filter(t => t.id !== type_object.id);
            this.checked(false, type_object);
        }

        this.props.selectVehicleGroup(current_groups)
    };

    checked = (checked, type_object) => {
        let groups = this.props.groups;
        const index = groups.findIndex(t => t.id === type_object.id);

        groups[index].checked = checked;

        this.props.getVehicleGroup([]);
        this.props.getVehicleGroup(groups);
    };

    clear = () => {
        let groups = this.props.groups;

        groups = groups.map(t => {
            t.checked = false;
            return t;
        });

        this.props.getVehicleGroup([]);
        this.props.getVehicleGroup(groups);
        this.props.selectVehicleGroup([]);
    };

    componentDidMount() {
        this.props.axiosGetVehicleGroup();
    }

    render() {
        const {
            lng,
            classes,
            groups,
            selected_groups
        } = this.props;

        const {open} = this.state;

        const selected = selected_groups.map(s => {
            return s.name
        });
        const placeholder = (selected.length > 0) ? selected.join(', ') : lng.choose_vehicle_group;

        return (
            <React.Fragment>
                <Paper className={classes.choose}>
                    <CarIcon />
                    <Typography className={classes.name} onClick={() => this.setState({open: true})}>
                        {placeholder}
                    </Typography>
                    {selected.length > 0 &&
                        <IconButton onClick={() => this.clear()}>
                            <ClearIcon className={classes.clear}/>
                        </IconButton>
                    }
                </Paper>

                <Dialog
                    open={open}
                    onClose={() => this.setState({open: false})}
                    PaperProps={{classes: {root: classes.dialog}}}
                >
                    <DialogTitle>{lng.choose_vehicle_group}</DialogTitle>
                    <DialogContent>
                        {groups.map(v => {
                            return (
                                <div key={v.id}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox checked={v.checked} value={v.id} onChange={(e) => this.selectGroup(e,v)} />
                                        }
                                        label={v.name}
                                    />
                                </div>
                            )
                        })}
                    </DialogContent>
                </Dialog>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
    return {
        lng: state.common.lng,
        groups: state.common.vehicleGroups,
        selected_groups: state.common.selectedVehicleGroups
    }
};

const mapDispatchToProps = {
    getVehicleGroup,
    axiosGetVehicleGroup,
    selectVehicleGroup
};

export default connect(mapStateToProps,mapDispatchToProps)(withStyles(styles)(ChooseVehicle))