export default function russian() {
    return {
        login: 'Вход',
        logout: 'Выход',
        password: 'Пароль',
        email: 'Почта',
        email_address: 'Эл. почта',
        start: 'Старт',
        today: 'Сегодня',
        tomorrow: 'Завтра',
        filter: 'Фильтр',
        choose_vehicle_type: 'Выбрать тип авто',
        choose_vehicle_group: 'Выбрать групу авто',
        reg_number: 'Но. регистрации',
        yes: 'Да',
        no: 'Нет',
        make_photos: 'Фото',
        finish: 'Закончить',
        priority: 'Приоритет',
        planned_on: 'На',
        last_update: 'П.О.',
        add_defect: 'Добавить дефект',
        delete: 'Удалить',
        edit: 'Редактировать',
        add_new_task: 'Добавить новую задачу',
        edit_the_task: 'Изменить задачу',
        save: 'Сохранить',
        close: 'Закрыть',
        cancel: 'Отмена',
        ok: 'Да',
        kilometers: 'Километраж',
        sign_in: 'Вход',
        tasks: 'Задачи',
        delete_task: 'Удалить задачу',
        are_you_sure: 'Вы уверены?',
        checklist: 'Контрольный список',
        date: 'Дата',
        comment: 'Комментарий',
        is_required: 'обязателен',
        field_required: 'Обязательно для заполнения',
        small_mileage: 'Введен слишком маленький километраж',
        name: 'Название'
    }
}