import {FETCH_TASKS} from './actions';

const initialState = {
    tasksList: []
};

const tasksReducers = (state, action) => {
    switch (action.type) {
        case FETCH_TASKS:
            return {...state, tasksList: action.payload};

        default:
            return initialState;
    }
};

export default tasksReducers;