import React, {Component} from 'react';
import {connect} from 'react-redux';
import Login from '../pages/Login';
import {axiosLogin, displayMessageBar, setLoginStatus} from '../store/common/actions';

class LoginContainer extends Component {

    login = async (email, pass) => {
        await this.props.login(email, pass);

        if (this.props.user.token !== undefined) {
            window.location.reload();
        } else {

        }
    };

    componentDidUpdate() {
        if (this.props.login_status === false) {
            this.props.displayMessageBar('Неверный емайл или пароль', 'error');
            this.props.setLoginStatus(null);
        } else if (this.props.login_status === true) {
            window.location.reload();
        }
    }

    render() {
        return <Login
                    login={this.login}
                    lng={this.props.lng}
                />
    }
}

const mapStateToProps = state => {
    return {
        user: state.common.user,
        lng: state.common.lng,
        login_status: state.common.login_status,
    }
};

const mapDispatchToProps = {
    login: axiosLogin,
    displayMessageBar,
    setLoginStatus
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);