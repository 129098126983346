import React, {Component} from 'react';
import {connect} from 'react-redux';
import {
    axiosFetchTasks,
    fetchTasks,
    axiosFetchTasksTest,
    setToolbarTitle
} from '../../store/common/actions';
import {showPreloader, hidePreloader} from '../../store/common/actions';
import TaskList from '../../layouts/TaskList';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { createStyles, withStyles } from '@material-ui/core/styles';
import Preloader from '../../layouts/Preloader';
import {COLORS} from "../../constants";

const styles = theme => createStyles({
    root: {
        flexGrow: 1,
        marginBottom:20
    },

    page: {
        textAlign: 'center'
    },

    tab: {
        textTransform: 'none'
    },

    tabIndicator: {
        backgroundColor: COLORS.red
    }
});

let current_date = new Date();

let due_dates = [
    current_date.getFullYear()+'-'+(current_date.getMonth() + 1)+'-'+current_date.getDate()
];

current_date.setDate( current_date.getDate() + 1 );

due_dates.push(current_date.getFullYear()+'-'+(current_date.getMonth() + 1)+'-'+current_date.getDate());

class DashboardIndex extends Component {
    state = {
        value: 0
    };

    changeTap = (event, newValue) => {
        const args = (newValue === 0) ? {overdue: due_dates[newValue], due_date: due_dates[newValue]} : {due_date: due_dates[newValue]};
        this.loadTasks(args);
        this.setState({value: newValue})
    };

    loadTasks = async (args) => {
        this.props.fetchTasks([]);
        this.props.showPreloader();

        await this.props.axiosFetchTasks(args);

        this.props.hidePreloader();
    };

    componentDidMount() {
        this.props.setToolbarTitle(this.props.lng.tasks);
        this.loadTasks({overdue: due_dates[0], due_date: due_dates[0]})
    }

    render() {
        const {classes} = this.props;
        const {value} = this.state;

        return (
            <div className={classes.page}>
                <Paper className={classes.root}>
                    <Tabs
                        value={value}
                        onChange={this.changeTap}
                        indicatorColor="primary"
                        textColor="primary"
                        centered
                        TabIndicatorProps={{classes: {root: classes.tabIndicator}}}
                    >
                        <Tab className={classes.tab} label={this.props.lng.today} />
                        <Tab className={classes.tab} label={this.props.lng.tomorrow} />
                    </Tabs>
                </Paper>

                <TaskList/>
                <Preloader />
            </div>
        )
    }
}

const mapStateToProps = state => {
    return {
        lng: state.common.lng
    }
};

const mapDispatchToProps = {
    axiosFetchTasks,
    fetchTasks,
    showPreloader,
    hidePreloader,
    setToolbarTitle
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(DashboardIndex));