import {SHOW_PRELOADER, HIDE_PRELOADER} from './actions';

const initialState = {
    showPreloader: false
};

const generalReducers = (state, action) => {
    switch (action.type) {
        case SHOW_PRELOADER:
            return {...state, showPreloader: action.payload};

        case HIDE_PRELOADER:
            return {...state, showPreloader: action.payload};

        default:
            return initialState;
    }
};

export default generalReducers;