import React, {Component} from 'react';
import {connect} from 'react-redux';
import { createStyles, withStyles } from '@material-ui/core/styles';
import CameraIcon from '@material-ui/icons/Camera';
import ClearIcon from '@material-ui/icons/Clear';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import {setPhotos, displayMessageBar, axiosSendPhoto} from '../store/common/actions';
import LinearProgress from '@material-ui/core/LinearProgress';
import {COLORS} from "../constants";
import {isMobile} from 'react-device-detect';
import {BASE_MEDIA_URL} from "../constants";

const styles = theme => createStyles({
    btn: {
        color: COLORS.black,
        backgroundColor: COLORS.gray,
        borderRadius: 4,
        display: 'inline-flex',
        padding: '5px 15px',
        alignItems: 'center',
        lineHeight: 1,
        minWidth: 150,
        height: 30,
        justifyContent: 'center',
        position: 'relative',
        overflow: 'hidden',

        ['&:hover, &:focus']: {
            color: COLORS.black,
            backgroundColor: COLORS.gray,
        },

        ['&.loading']: {
            pointerEvents: 'none'
        }
    },

    btn_preloader: {
        position: 'absolute',
        width: '100%',
        left: 0,
        top: 0,
        display: 'none',

        ['&.show']: {
            display: 'block'
        }
    },

    icon: {
        marginRight: 10
    },

    wrap: {
        textAlign: 'center'
    },

    list: {
        listStyle: 'none',
        padding: 0,
        margin: '0 -3px',
        textAlign: 'center'
    },

    list_item: {
        display: 'inline-block',
        padding: 6,
        position: 'relative'
    },

    list_img: {
        width: '100px',
        height: '100px',
        backgroundSize: 'cover',
        backgroundPosition: 'center'
    },

    list_remove: {
        color: '#fff',
        backgroundColor: '#f00',
        outline: 'none !important',
        width: 30,
        height: 30,
        padding: 0,
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        margin: 'auto',

        ['&:hover, &:focus']: {
            color: '#fff',
            backgroundColor: '#f00',
        }
    }
});

class MakePhoto extends Component {
    constructor(props) {
        super(props);

        this.button = React.createRef();
    }

    upload = async (e) => {
        const preloader = document.getElementById('make_photo_preloader');

        if (this.props.photos.length === 5) {
            this.props.displayMessageBar('Вы можете загрузить только 5 изображений', 'error');
            return false;
        }

        if (e.currentTarget.files.length > 0) {
            const file = e.currentTarget.files[0];
            preloader.classList.add('show');

            if (this.button.current !== null) {
                this.button.current.classList.add('loading');
            }

            const path = await axiosSendPhoto(file);

            if (path.length > 0) {
                let photos = this.props.photos;

                photos.push({
                    preview: BASE_MEDIA_URL+path,
                    origin: path
                });

                this.props.setPhotos([]);
                this.props.setPhotos(photos);
            }

            preloader.classList.remove('show');

            if (this.button.current !== null) {
                this.button.current.classList.remove('loading');
            }
        }
    };

    remove = (index) => {
        let photos = this.props.photos.filter((img,i) => i !== index);
        this.props.setPhotos(photos);
    };

    componentDidMount() {
        this.props.setPhotos([]);
    }


    render() {
        const {lng, classes, photos} = this.props;

        return (
            <div className={classes.wrap}>
                <ul className={classes.list}>
                    {photos.map( (img, i) => {
                        return (
                            <li key={i} className={classes.list_item}>
                                <CardMedia
                                    image={img.preview}
                                    title="Image"
                                    className={classes.list_img}
                                >
                                    {/*<ExifOrientationImg width="100" height={100} styles={{objectFit:'cover'}} src={img.preview}/>*/}
                                </CardMedia>
                                <IconButton className={classes.list_remove} onClick={() => this.remove(i)}>
                                    <ClearIcon/>
                                </IconButton>
                            </li>
                        )
                    } )}
                </ul>
                <input
                    type="file"
                    id="make_photo_input"
                    accept="image/*"
                    disabled={!isMobile}
                    capture="camera"
                    style={{display: 'none'}}
                    onChange={this.upload}
                />
                <label ref={this.button} htmlFor="make_photo_input" className={classes.btn}>
                    <LinearProgress id="make_photo_preloader" className={classes.btn_preloader} color="secondary"/>
                    <CameraIcon className={classes.icon} /> {lng.make_photos}
                </label>
            </div>
        )
    }
}

const mapStateToProps = state => {
  return {
      lng: state.common.lng,
      photos: state.common.photos
  }
};

const mapDispacthToProps = {
    setPhotos,
    displayMessageBar
};

export default connect(mapStateToProps, mapDispacthToProps)(withStyles(styles)(MakePhoto));