import React, {Component} from 'react';
import {axiosGetRegistrationNumbers, selectRegistrationNumber} from "../../store/common/actions";
import {connect} from 'react-redux';
import InputLabel from "@material-ui/core/InputLabel";
import Select from "react-select";
import {createStyles, withStyles} from "@material-ui/core/styles";

const styles = theme => createStyles({
    label: {
        textAlign: 'left',
        fontSize: 12,
        marginBottom: 10,
        // paddingLeft: 16
    }
});

class RegistrationNumber extends Component {
    componentDidMount() {
        this.props.selectRegistrationNumber([]);
        this.props.axiosGetRegistrationNumbers();
    }

    select = (e) => {
        let result = e === null ? [] : e;

        if (this.props.isMulti === false) {
            result = e === null ? [] : [e];
        }

        this.props.selectRegistrationNumber(result);
    };

    render() {
        const {
            reg_numbers,
            lng,
            classes,
            isMulti,
            defaultValue
        } = this.props;

        const options = reg_numbers.map(v => {
            return {
                value: v.id,
                label: v.number
            }
        });

        const multi_prop = (isMulti === undefined) ? true : isMulti;
        const value = (defaultValue === undefined) ? false : defaultValue;

        return (
            <div>
                <InputLabel className={classes.label}>{lng.reg_number}</InputLabel>
                <Select name='reg_number_selector' defaultValue={value}  isMulti={multi_prop} options={options} onChange={this.select} />
            </div>
        )
    }
};

const mapStateToProps = state => {
    return {
        lng: state.common.lng,
        reg_numbers: state.common.reg_numbers
    }
};

const mapDispatchToProps = {
    axiosGetRegistrationNumbers,
    selectRegistrationNumber
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RegistrationNumber))